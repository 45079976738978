import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Table, Modal, Button } from "react-bootstrap";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import "../i18n"
import i18n from '../i18n';
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FieldType from "../components/FieldType";
import { download, loadJson, validateEmail } from "../lib/util";
import LangSwitcher from "../components/LangSwitcher";
import CustomAccommodations from "../components/CustomAccommodations";
import CustomAccommodationsReadOnly from "../components/CustomAccommodationsReadOnly";
import ModalWindow from "../components/ModalWindow";

import FooterFrancesPlannerEn from '../components/footer/FooterFrancesPlannerEn';
import FooterFrancesPlannerIt from '../components/footer/FooterFrancesPlannerIt';
import FooterNortePlannerEn from '../components/footer/FooterNortePlannerEn';
import FooterNortePlannerDe from '../components/footer/FooterNortePlannerDe';
import FooterPortuguesPlannerEn from '../components/footer/FooterPortuguesPlannerEn';
import FooterPortuguesPlannerDe from '../components/footer/FooterPortuguesPlannerDe';

import FooterPrimitivoPlannerEn from '../components/footer/FooterPrimitivoPlannerEn';
import FooterVDLPPlannerEn from '../components/footer/FooterVDLPPlannerEn';
import FooterInviernoPlannerEn from '../components/footer/FooterInviernoPlannerEn';
import FooterGeiraPlannerEn from '../components/footer/FooterGeiraPlannerEn';
import FooterLevantePlannerEn from '../components/footer/FooterLevantePlannerEn';
import FooterMadridPlannerEn from '../components/footer/FooterMadridPlannerEn';
import FooterNascentePlannerEn from '../components/footer/FooterNascentePlannerEn';
import FooterOlvidadoPlannerEn from '../components/footer/FooterOlvidadoPlannerEn';
import FooterPodiensisPlannerEn from '../components/footer/FooterPodiensisPlannerEn';
import FooterSalvadorPlannerEn from '../components/footer/FooterSalvadorPlannerEn';
import FooterFinisterrePlannerEn from '../components/footer/FooterFinisterrePlannerEn';
import FooterMuxiaPlannerEn from '../components/footer/FooterMuxiaPlannerEn';


import HeaderFrancesPlanner from '../components/header/HeaderFrancesPlanner';
import HeaderNortePlanner from '../components/header/HeaderNortePlanner';
import HeaderPortuguesCentralPlanner from '../components/header/HeaderPortuguesCentralPlanner';
import HeaderPortuguesCoastalPlanner from '../components/header/HeaderPortuguesCoastalPlanner';
import HeaderPortuguesVariantePlanner from '../components/header/HeaderPortuguesVariantePlanner';
import HeaderPrimitivoPlanner from '../components/header/HeaderPrimitivoPlanner';
import HeaderVDLPPlanner from '../components/header/HeaderVDLPPlanner';
import HeaderInviernoPlanner from '../components/header/HeaderInviernoPlanner';
import HeaderGeiraPlanner from '../components/header/HeaderGeiraPlanner';
import HeaderLevantePlanner from '../components/header/HeaderLevantePlanner';
import HeaderMadridPlanner from '../components/header/HeaderMadridPlanner';
import HeaderNascentePlanner from '../components/header/HeaderNascentePlanner';
import HeaderOlvidadoPlanner from '../components/header/HeaderOlvidadoPlanner';
import HeaderPodiensisPlanner from '../components/header/HeaderPodiensisPlanner';
import HeaderSalvadorPlanner from '../components/header/HeaderSalvadorPlanner';
import HeaderFinisterrePlanner from '../components/header/HeaderFinisterrePlanner';
import HeaderMuxiaPlanner from '../components/header/HeaderMuxiaPlanner';

function Itinerary({ itineraryType, hideControls }) {
  const { t } = useTranslation();

  //const bookingAffiliate = [2237640, 2241113];
  const bookingAffiliate = [2237640, 2237640];
  const [radomBookingAffId, setRadomBookingAffId] = useState();
  const [lang, setLang] = useState("en");
  const [fetched, setFetched] = useState(false);
  const [dateCalculated, setDateCalculated] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedSubIds, setSelectedSubIds] = useState([]);
  const [selDate, setSelDate] = useState("");
  const [dDates, setDDates] = useState([]);
  const [enteredDates, setEnteredDates] = useState("");
  const [lastRecord, setLastRecord] = useState(0);
  const [customAccommodations, setCustomAccommodations] = useState([]);
  const [newCustomAccommodation, setNewCustomAccommodation] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  let accommodationObj = [];
  let distance = 0;
  let lastDistance = 0;
  let lastDistanceDisplay = 0;
  let lastDistanceForNonSelected = 0;
  let distanceInitiate = false;
  let sn = 1;
  let params = useParams();
  const [uuid, setUuid] = useState(params.uuid);
  const calculateDistance = (item) => {
    let itemId = item.id;
    let itemDistance = item.to_end;
    if (selectedIds.length == 0) {
      // if nothing is selected
      return "-";
    }
    if (selectedIds.includes(itemId) && !distanceInitiate) {
      // Item selected - first record will display 0.00
      //distance = 0;
      //  lastDistance = 0;
      distanceInitiate = true;
      lastDistanceForNonSelected = 0;
      distance = lastDistance = itemDistance;
      return parseFloat(0).toFixed(1);
    } else if (selectedIds.includes(itemId)) {
      // Item selected - 2nd record onward
      if (item.deviation) {
        lastDistanceDisplay = distance =
          lastDistance -
          itemDistance -
          lastDistanceForNonSelected -
          parseFloat(item.deviation);
        lastDistanceForNonSelected = distance = lastDistance - itemDistance;
      } else {
        lastDistanceDisplay = distance =
          lastDistance - itemDistance - lastDistanceForNonSelected;
        lastDistanceForNonSelected = distance = lastDistance - itemDistance;
      }
      // last distance = 767. i.e, the final distance
      return parseFloat(lastDistanceDisplay).toFixed(1);
    } else {
      // non selected records
      if (!distanceInitiate) {
        // non selected above first record
        return "-";
      } else {
        // non selected records
        //distance = lastDistance - itemDistance;
        lastDistanceDisplay = distance =
          lastDistance - itemDistance - lastDistanceForNonSelected;
        return parseFloat(distance).toFixed(1);
        //return parseFloat(distance).toFixed(1);
      }
    }
  };
  useEffect(() => {
    async function fetchData() {
      if (typeof uuid !== "undefined" && uuid != null) {
        const apiResponse = await axios.get(
          process.env.REACT_APP_API_URL + "api.php",
          { params: { uuid } }
        );
        setLang(apiResponse.data.itinerary.lang);

        if (
          apiResponse.status === 200 &&
          apiResponse.data.status === "success"
        ) {
          if (
            typeof apiResponse.data.itinerary.sel_ids !== "undefined" &&
            apiResponse.data.itinerary.sel_ids != null
          ) {
            setSelectedIds(apiResponse.data.itinerary.sel_ids.split(","));
            if (
              typeof apiResponse.data.itinerary.sel_sub_ids !== "undefined" &&
              apiResponse.data.itinerary.sel_sub_ids != null
            ) {
              setSelectedSubIds(
                apiResponse.data.itinerary.sel_sub_ids.split(",")
              );
            }
            if (
              typeof apiResponse.data.itinerary.dates !== "undefined" &&
              apiResponse.data.itinerary.dates != null
            ) {
              const parsedDate = new Date(apiResponse.data.itinerary.dates);
              //console.log('settingup dates==',parsedDate)
              console.log(
                "settingup dates==",
                apiResponse.data.itinerary.dates
              );
              //setEnteredDates(parsedDate);
              setUpDate(apiResponse.data.itinerary.dates);
            }
            if (
              typeof apiResponse.data.itinerary.addon_accommodations !==
                "undefined" &&
              apiResponse.data.itinerary.addon_accommodations != null
            ) {
              console.log(
                "Accomodation db===",
                JSON.parse(apiResponse.data.itinerary.addon_accommodations)
              );
              setCustomAccommodations(
                JSON.parse(apiResponse.data.itinerary.addon_accommodations)
              );
            }
          }
          //loading json
          const res = await axios.get(
            process.env.REACT_APP_URL +
              "/planner-json/" +
              loadJson(itineraryType, apiResponse.data.itinerary.lang)
          );
          await setDestinations(res.data); //.reverse()
        }
      } else {
        //loading json
        const res = await axios.get(
          process.env.REACT_APP_URL + "/planner-json/" + loadJson(itineraryType, lang)
        );
        await setDestinations(res.data); //.reverse()
      }
      const random = Math.floor(Math.random() * bookingAffiliate.length);
      setRadomBookingAffId(bookingAffiliate[random]);
      // picking radom aff id
      setFetched(true);
      setLoading(false);
    }
    fetchData();
  }, [fetched]);
  useEffect(() => {
    // run a loop of destinations
    // if id found in selectedIds then keep on increasing the date and add it in a new array dateHolder[id] = incrementeddate
    if (!dateCalculated) {
      //return true;
      //}
      let cntr = 0;
      destinations.map(async (item, index) => {
        if (selectedIds.includes(item.id)) {
          if (selDate) {
            // date is seleced
            const fDate = new Date(
              selDate.getFullYear(),
              selDate.getMonth(),
              selDate.getDate() + cntr,
              0,
              0,
              0,
              0
            );
            const dateObj = { it: item.id, dd: fDate };
            setDDates((dDates) => [
              ...dDates,
              { id: item.id, km: item.to_end, dd: fDate },
            ]);
            cntr++;
          }
        }
      });
      setDateCalculated(true);
    }
  }, [selectedIds]);
  const toggleTable = async (item) => {


    if (item) {
      let id = item.id;
      setDDates([]);
      setDateCalculated(false);
      if (!selectedIds.includes(id)) {
        await setSelectedIds([...selectedIds, id]);
        setLastRecord(id);
        await setSelectedItems([...selectedItems, item]);
      } else {
        const arr = selectedIds;
        const indx = arr.indexOf(id);
        if (indx != -1) {
          arr.splice(indx, 1);
          await setSelectedIds([...arr, 0]);
        }
      }
    }
    console.log('end');
    setTimeout(()=>{
      setLoading(false);
    }, 1000)

  };
  const toggleSubTable = async (id) => {
    //removed spinner, not necessary
    //setLoading(true);
    setDDates([]);
    setDateCalculated(false);
    if (!selectedSubIds.includes(id)) {
      await setSelectedSubIds([...selectedSubIds, id]);
    } else {
      const arr = selectedSubIds;
      const indx = arr.indexOf(id);
      if (indx != -1) {
        arr.splice(indx, 1);
        await setSelectedSubIds([...arr, 0]);
      }
    }
    //setLoading(false);
  };
  const setUpDate = async (dateVal) => {
    if (dateVal) {
      console.log("settingup dates==ac=", dateVal);
      setEnteredDates(dateVal);
      const dateArr = dateVal.split("-");
      const sDate = new Date(
        dateArr[0],
        dateArr[1] - 1,
        dateArr[2],
        0,
        0,
        0,
        0
      );
      console.log("selDate=====", sDate);
      const selDateHolder = selDate;
      setSelDate(sDate);
    } else {
      setSelDate("");
    }
    const holder = selectedIds[0];
    await toggleTable(holder);
  };
  const handleDateChange = async (e) => {
    await setUpDate(e.target.value);
  };
  const calculateDate = (id) => {
    const itineraryDate = dDates.filter(function (el) {
      return el.id == id;
    });
    if (itineraryDate[0]) {
      const itineraryDateVar = itineraryDate[0].dd;
      const yyyy = itineraryDateVar.getFullYear();
      let mm = itineraryDateVar.getMonth() + 1; // Months start at 0!
      let dd = itineraryDateVar.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      const today = dd + "-" + mm + "-" + yyyy;
      if (itineraryDateVar.getDay() == 0) {
        return Parser(
          " " + today + "<br /><strong className='badge bg-danger'>Sunday</strong>"
        );
      } else {
        return " " + today;
      }
    }
  };
  const DisplayDate = ({ id }) => {
    return calculateDate(id);
  };
  const exportToHTML = () => {
    if (!selectedIds.length) {
      alert("Please select an itinerary to export HTML");
      return true;
    }
    const htmlcontent = document.querySelector("html");
    let parsedHTML = htmlcontent.innerHTML.replaceAll(
      'hideparam="hide-this-for-html-export"',
      "style='display:none'"
    );
    parsedHTML = parsedHTML.replaceAll(
      'href="/static',
      'href="' + process.env.REACT_APP_URL + "/static"
    );
    parsedHTML = parsedHTML.replaceAll(
      "href='/static",
      "href='" + process.env.REACT_APP_URL + "/static"
    );
    download(
      "index.html",
      '<!DOCTYPE html><html lang="en">' + parsedHTML + "</html>"
    );
  };
  const formatBookingUrl = (url, id, days) => {
    url = url.replace("383068", radomBookingAffId);
    const bookingDate = dDates.filter(function (el) {
      return el.id == id;
    });
    if (bookingDate[0]) {
      const bookingDateStart = bookingDate[0].dd;
      console.log("itineraryDateVar==", bookingDateStart);
      const yyyy = bookingDateStart.getFullYear();
      const mm = bookingDateStart.getMonth() + 1; // Months start at 0!
      const dd = bookingDateStart.getDate();
      //var bookingDateEnd = bookingDateStart;
      const addDays = 1;
      //const bookingDateEnd = new Date(bookingDateStart.setDate(bookingDateStart.getDate() + addDays));
      //const bookingDateEnd = new Date(bookingDateStart.getDate() + addDays);
      const cdate = new Date(yyyy + "-" + mm + "-" + dd);
      var bookingDateEnd = cdate.setDate(cdate.getDate() + 1);
      bookingDateEnd = new Date(bookingDateEnd);
      //console.log('test===',bookingDateEnd)
      const yyyyEnd = bookingDateEnd.getFullYear();
      let mmEnd = bookingDateEnd.getMonth() + 1; // Months start at 0!
      let ddEnd = bookingDateEnd.getDate();
      console.log(dd, mm, yyyy);
      console.log(ddEnd, mmEnd, yyyyEnd);
      return (url +=
        "&checkin_monthday=" +
        dd +
        "&checkin_month=" +
        mm +
        "&checkin_year=" +
        yyyy +
        "&checkout_monthday=" +
        ddEnd +
        "&checkout_month=" +
        mmEnd +
        "&checkout_year=" +
        yyyyEnd);
    }
    // console.log('formattedurl=',url);
    return url;
  };
  const saveItinerary = async () => {
    if (!email) {
      alert("Please enter a valid email address to save the itinerary!");
      return false;
    }
    const saveUrl = process.env.REACT_APP_API_URL + "api.php";
    const itineraryData = {
      selectedIds: selectedIds.join(","),
      selectedSubIds: selectedSubIds.join(","),
      customAccommodations: JSON.stringify(customAccommodations),
      dates: enteredDates,
      email,
      url: process.env.REACT_APP_URL,
      itineraryType,
      lang,
    };
    console.log("submitting", itineraryData);
    const response = await axios.post(saveUrl, itineraryData);
    if (response.data.status == "success") {
      setShareUrl(
        process.env.REACT_APP_URL +
          "/" +
          itineraryType +
          "/" +
          response.data.uuid
      );
      setShowModal(true);
      //navigate("/"+itineraryType+"/" + response.data.uuid);
    } else {
      alert("Error in saving itinerary");
    }
  };
  const addCustomAccomodation = (id, name) => {
    if (newCustomAccommodation) {
      let accommodationFound = customAccommodations.filter(function (el) {
        return el.id == id;
      });
      let updatedAccommodations = customAccommodations.map(function (el) {
        if (el.id == id) {
          el.accommodation = [
            ...el.accommodation,
            { name: newCustomAccommodation },
          ];
          return el;
        }
        return el;
      });
      if (!accommodationFound.length) {
        updatedAccommodations = [
          ...customAccommodations,
          { id, accommodation: [{ name: newCustomAccommodation }] },
        ];
      }
      setCustomAccommodations(updatedAccommodations);
    }
    setNewCustomAccommodation("");
    console.log("cleared");
  };
  const deleteCustomAccomodation = (id, name) => {
    const accret = customAccommodations.map(function (el) {
      //return el.id == id;
      if (el.id == id) {
        let accommodation = el.accommodation.filter(function (subel) {
          return subel.name != name;
        });
        el.accommodation = accommodation;
        return el;
      }
      return el;
    });
    setCustomAccommodations(accret);
  };
  const saveEmailAddress = (e) => {
    if (validateEmail(e.target.value)) {
      console.log("Valid", e.target.value);
      setEmail(e.target.value);
    } else {
      console.log("Invalid", e.target.value);
      setEmail("");
    }
  };
  const exportToPDF = (pdfType) => {
    console.log("pdftype==", pdfType);
    if (!selectedIds.length) {
      alert("Please select an itinerary to export pdf");
      return true;
    }
    let pdfurl =
      process.env.REACT_APP_API_URL +
      "?id=" +
      selectedIds.join(",") +
      "&pdfType=" +
      pdfType +
      "&itineraryType=" +
      itineraryType +
      "&lang=" +
      lang +
      "&subids=" +
      selectedSubIds.join(",") +
      "&date=" +
      enteredDates +
      "&affid=" +
      radomBookingAffId +
      "&lastRecord=" +
      lastRecord;
    const anchor = document.createElement("a");
    // Create an href that contains the contents of the file
    // encodeURIComponent encodes a text string as a valid component of a URI
    anchor.setAttribute("href", pdfurl);
    anchor.setAttribute("target", "_blank");
    // set the download attribute to download the given filename
    anchor.setAttribute("download", "wise-pilgrim-guides.pdf");
    // Anchor tag should be invisible
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    // Fire the event
    anchor.click();
    // Remove the anchor tag
    document.body.removeChild(anchor);
  };
  let firstMatchFound = false;
  let lastMatchFound = false;
  let displayLastMatchFound = 0;

  const LoadFooter = () => {
      if( itineraryType=='Camino-Frances' && (lang=='' || lang=='en')) {
        return <FooterFrancesPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Frances' && lang=='it') {
        return <FooterFrancesPlannerIt t={t} />
      }
      if( itineraryType=='Camino-Del-Norte' && (lang=='' || lang=='en')) {
        return <FooterNortePlannerEn t={t} />
      }
      if( itineraryType=='Camino-Del-Norte' && lang=='de') {
        return <FooterNortePlannerDe t={t} />
      }


      if( itineraryType=='Camino-Portugues-Central' && (lang=='' || lang=='en')) {
        return <FooterPortuguesPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Portugues-Central' && lang=='de') {
        return <FooterPortuguesPlannerDe t={t} />
      }

      if( itineraryType=='Camino-Portugues-Coastal' && (lang=='' || lang=='en')) {
        return <FooterPortuguesPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Portugues-Coastal' && lang=='de') {
        return <FooterPortuguesPlannerDe t={t} />
      }

      if( itineraryType=='Camino-Portugues-Variante' && (lang=='' || lang=='en')) {
        return <FooterPortuguesPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Portugues-Variante' && lang=='de') {
        return <FooterPortuguesPlannerDe t={t} />
      }



      if( itineraryType=='Camino-Primitivo' && (lang=='' || lang=='en')) {
        return <FooterPrimitivoPlannerEn t={t} />
      }
      if( itineraryType=='Via-de-la-Plata' && (lang=='' || lang=='en')) {
        return <FooterVDLPPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Invierno' && (lang=='' || lang=='en')) {
        return <FooterInviernoPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Olvidado' && (lang=='' || lang=='en')) {
        return <FooterOlvidadoPlannerEn t={t} />
      }
      if( itineraryType=='Camino-San-Salvador' && (lang=='' || lang=='en')) {
        return <FooterSalvadorPlannerEn t={t} />
      }
      if( itineraryType=='Caminho-Nascente' && (lang=='' || lang=='en')) {
        return <FooterNascentePlannerEn t={t} />
      }
      if( itineraryType=='Caminho-da-Geira' && (lang=='' || lang=='en')) {
        return <FooterGeiraPlannerEn t={t} />
      }
      if( itineraryType=='Via-Podiensis' && (lang=='' || lang=='en')) {
        return <FooterPodiensisPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Levante' && (lang=='' || lang=='en')) {
        return <FooterLevantePlannerEn t={t} />
      }
      if( itineraryType=='Camino-Madrid' && (lang=='' || lang=='en')) {
        return <FooterMadridPlannerEn t={t} />
      }
      if( itineraryType=='Camino-Santiago-Finisterre' && (lang=='' || lang=='en')) {
        return <FooterFinisterrePlannerEn t={t} />
      }
      if( itineraryType=='Camino-Santiago-Muxia' && (lang=='' || lang=='en')) {
        return <FooterMuxiaPlannerEn t={t} />
      }


  }
  const LoadHeader = () => {
      if( itineraryType=='Camino-Frances') {
        return <HeaderFrancesPlanner />
      }

      if( itineraryType=='Camino-Del-Norte') {
        return <HeaderNortePlanner />
      }

      if( itineraryType=='Camino-Portugues-Central' ) {
        return <HeaderPortuguesCentralPlanner />
      }

      if( itineraryType=='Camino-Portugues-Coastal' ) {
        return <HeaderPortuguesCoastalPlanner />
      }

      if( itineraryType=='Camino-Portugues-Variante' ) {
        return <HeaderPortuguesVariantePlanner />
      }
      if( itineraryType=='Via-de-la-Plata' ) {
        return <HeaderVDLPPlanner />
      }
      if( itineraryType=='Camino-Primitivo' ) {
        return <HeaderPrimitivoPlanner />
      }
      if( itineraryType=='Camino-Olvidado' ) {
        return <HeaderOlvidadoPlanner />
      }
      if( itineraryType=='Camino-San-Salvador' ) {
        return <HeaderSalvadorPlanner />
      }
      if( itineraryType=='Caminho-Nascente' ) {
        return <HeaderNascentePlanner />
      }
      if( itineraryType=='Caminho-da-Geira' ) {
        return <HeaderGeiraPlanner />
      }
      if( itineraryType=='Via-Podiensis' ) {
        return <HeaderPodiensisPlanner />
      }
      if( itineraryType=='Camino-Levante' ) {
        return <HeaderLevantePlanner />
      }
      if( itineraryType=='Camino-Madrid' ) {
        return <HeaderMadridPlanner />
      }
      if( itineraryType=='Camino-Invierno' ) {
        return <HeaderInviernoPlanner />
      }
      if( itineraryType=='Camino-Santiago-Finisterre' ) {
        return <HeaderFinisterrePlanner />
      }
      if( itineraryType=='Camino-Santiago-Muxia' ) {
        return <HeaderMuxiaPlanner />
      }





  }

  return (
    <>
     {loading && <div id="cover-spin"></div>}
      {showModal && (
        <ModalWindow t={t} setShowModal={setShowModal} shareUrl={shareUrl} />
      )}
      <Container>

        <LoadHeader />
        <div class="row">
          <div class="col">
            <div class="text-btn-main2">{"Step 1 - Choose a date"}</div>
            <div class="text-btn-sub2">{"Select your starting date below, this is the night before you intend to start walking."}</div>
            <div class="text-btn-sub2">{"(optional, but helpful for planning as it will be included in the pdf)"}</div>
            <div class="text-btn-main2">{"Step 2 - Select start"}</div>
            <div class="text-btn-sub2">{"Select your Starting Point"}</div>
            <div class="text-btn-sub2">{"It will turn blue and will be remembered, like a bookmark."}</div>
            <div class="text-btn-sub2">{"Optionally, select your accommodation.  It too will turn blue."}</div>
            <div class="text-btn-sub2">{"Distances will automatically calculate between this city and the next one you choose."}</div>

            <div class="text-btn-main2">{"Step 3 - Select an end"}</div>
            <div class="text-btn-sub2">{"Select the ending point of each stage of walking, as many or as few as you like."}</div>
            <div class="text-btn-main2">{"Step 4 - Save/Print"}</div>
            <div class="text-btn-sub2">{"Save your itinerary at the bottom."}</div>
            <div class="text-btn-sub2">{"IF you save, you can easily come back later to add days or modify the ones you have already made."}</div>
            <div class="text-btn-sub2">{"-or-"}</div>
            <div class="text-btn-sub2">{"Save your itinerary to an interactive PDF to take with you or print."}</div>
          </div>
        </div>

        {!hideControls && (
          <Row className="align-items-end">
            <Col className="text-end mx-auto m-4">
              <div className="row g-3 align-items-end">
                <div className="col-12 d-flex">
                  <input
                    type="date"
                    className="form-control"
                    onChange={handleDateChange}
                  />
                  <LangSwitcher
                    i18n={i18n}
                    lang={itineraryType + "-" + lang}
                    setLang={setLang}
                    setFetched={setFetched}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}


        <Row>
          <Col>
            <Table responsive="xl" className="guide">
              <thead>
                <tr>
                  <th style={{ textAlign: "right" }}>#</th>
                  <th style={{ textAlign: "right" }}>KM</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>NIGHT</th>
                </tr>
              </thead>
              <tbody>
                {destinations.map((item, index) => {
                  if (selectedIds.includes(item.id)) {
                    firstMatchFound = true;
                  }
                  if (displayLastMatchFound > 0) displayLastMatchFound++;
                  if (item.id == lastRecord) {
                    lastMatchFound = true;
                    displayLastMatchFound++;
                  }
                  const accomodationArr = customAccommodations.filter(function (
                    el
                  ) {
                    return el.id == item.id;
                  });
                  if (
                    typeof accomodationArr[0] !== "undefined" &&
                    typeof accomodationArr[0].accommodation !== "undefined"
                  ) {
                    accommodationObj = accomodationArr[0].accommodation;
                  } else {
                    accommodationObj = [];
                  }
                  return (
                    <>
                      <tr
                        key={index}
                        onClick={() => {
                          //setLoading(true);
                          toggleTable(item);
                        }}
                        className={
                          selectedIds.includes(item.id)
                            ? " bg-selected  "
                            : item.color
                        }
                        hideparam={
                          firstMatchFound == false ||
                          (lastMatchFound == true && displayLastMatchFound > 1)
                            ? "hide-this-for-html-export"
                            : ""
                        }
                        role="button"
                      >
                        <td>
                          <strong>
                            {selectedIds.includes(item.id) && sn++}
                          </strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>{calculateDistance(item)}</strong>
                        </td>
                        <td width="18">
                          {item.booking ? (
                            <a
                              href={formatBookingUrl(item.booking, item.id, 1)}
                              target="_blank"
                              title="Click to reserve on Booking.com"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_URL +
                                  "/assets/img/icons/booking.png"
                                }
                                width="60"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <strong>{item.title}</strong>
                        </td>
                        <td width="18">
                          {item.shared_bunk && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/shared-bunk.png"
                              }
                              width="18"
                              title="Shared Accommodation"
                              alt="Shared Accommodation"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.hotel && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/hotel.png"
                              }
                              width="18"
                              title="Private Accommodation"
                              alt="Private Accommodation"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.camping && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/camping.png"
                              }
                              title="Camping"
                              alt="Camping"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.hospital && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/hospital.png"
                              }
                              title="Hospital"
                              alt="Hospital"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.pharmacy && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/pharmacy.png"
                              }
                              title="Pharmacy"
                              alt="Pharmacy"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.bar && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/bar.png"
                              }
                              title="Bar"
                              alt="Bar"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.grocery && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/grocery.png"
                              }
                              title="Grocery"
                              alt="Grocery"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.atm && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/atm.png"
                              }
                              title="ATM"
                              alt="ATM"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.atm && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/correos.png"
                              }
                              title="Correos"
                              alt="Correos"
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.bus && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/bus.png"
                              }
                              title=""
                              alt=""
                              width="18"
                            />
                          )}
                        </td>
                        <td width="18">
                          {item.train && (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "/assets/img/icons/train.png"
                              }
                              title=""
                              alt=""
                              width="18"
                            />
                          )}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {selectedIds.includes(item.id) ? (
                            <DisplayDate id={item.id} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="20">
                          <div>{item.body && Parser(item.body)}</div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="20">
                          <div>{item.road && Parser(item.road)}</div>
                        </td>
                      </tr>

                      <tr
                        className={
                          selectedIds.includes(item.id) ? " " : "d-none"
                        }
                      >
                        <td></td>
                        <td></td>
                        <td colSpan="14">
                          <Table responsive="xl">
                            <tbody>
                              {item.accommodation &&
                                JSON.parse(item.accommodation).map(
                                  (subitem, subindex) => {
                                    return (
                                      <tr
                                        className={
                                          selectedSubIds.includes(subitem.id)
                                            ? " bg-selected"
                                            : ""
                                        }
                                        onClick={() =>
                                          toggleSubTable(subitem.id)
                                        }
                                        role="button"
                                      >
                                        <td>
                                          {subitem.booking && (
                                            <a
                                              href={formatBookingUrl(
                                                subitem.booking,
                                                item.id,
                                                1
                                              )}
                                              target="_blank"
                                              title="Click to reserve on Booking.com"
                                            >
                                              <img
                                                src={
                                                  process.env.REACT_APP_URL +
                                                  "/assets/img/icons/booking.png"
                                                }
                                                alt="Click to reserve on Booking.com"
                                                width="60"
                                              />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          <FieldType
                                            fieldType={subitem.type}
                                          />
                                        </td>
                                        <td colSpan="6" className="sub-item-title">
                                          <strong>{subitem.title}</strong>
                                          {/* -{" "}
                                      <i>{subitem.address}</i>*/}
                                        </td>
                                        {subitem.beds === undefined ||
                                        subitem.beds === "" ? null : (
                                          <td
                                            colSpan="6"
                                            style={{ textAlign: "right" }}
                                          >
                                            {subitem.beds}{" "}
                                            <FontAwesomeIcon
                                              style={{ fontSize: 12 }}
                                              icon={faBed}
                                            />
                                          </td>
                                        )}
                                        {subitem.price === undefined ||
                                        subitem.price === "" ? null : (
                                          <td style={{ textAlign: "right" }}>
                                            {subitem.price}€
                                          </td>
                                        )}
                                        {/*<td>
                                          <a
                                            href={
                                              "tel:+33" +
                                              subitem.phone.replace(/ /g, "")
                                            }
                                          >
                                            +33 {subitem.phone}
                                          </a>
                                        </td>*/}
                                        <td>
                                          {subitem.pilgrim && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/pilgrim.png"
                                              }
                                              alt="Pilgrim Exclusive"
                                              title="Pilgrim Exclusive"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.res && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/res.png"
                                              }
                                              alt="Reservations Accepted"
                                              title="Reservations Accepted"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.pet && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/pet.png"
                                              }
                                              alt="Pet Friendly"
                                              title="Pet Friendly"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.bar && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/bar.png"
                                              }
                                              alt="Bar"
                                              title="Bar"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.kitchen && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/kitchen.png"
                                              }
                                              alt="Kitchen"
                                              title="Kitchen"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.group && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/group.png"
                                              }
                                              alt="Group Dinner"
                                              title="Group Dinner"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.veg && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/veg.png"
                                              }
                                              alt="Vegetarian Friendly"
                                              title="Vegetarian Friendly"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.wash && (
                                            <img
                                              src="/assets/img/icons/wash.png"
                                              alt="Washing Machine"
                                              title="Washing Machine"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.wifi && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/wifi.png"
                                              }
                                              alt="WiFi"
                                              title="WiFi"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {subitem.bike && (
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                "/assets/img/icons/bike.png"
                                              }
                                              alt="Bike Storage"
                                              title="Bike Storage"
                                              width="16"
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              {hideControls ? (
                                <CustomAccommodationsReadOnly
                                  accommodationObj={accommodationObj}
                                  deleteCustomAccomodation={
                                    deleteCustomAccomodation
                                  }
                                  item={item}
                                  newCustomAccommodation={
                                    newCustomAccommodation
                                  }
                                  addCustomAccomodation={addCustomAccomodation}
                                  setNewCustomAccommodation={
                                    setNewCustomAccommodation
                                  }
                                />
                              ) : (
                                <CustomAccommodations
                                  accommodationObj={accommodationObj}
                                  deleteCustomAccomodation={
                                    deleteCustomAccomodation
                                  }
                                  item={item}
                                  newCustomAccommodation={
                                    newCustomAccommodation
                                  }
                                  addCustomAccomodation={addCustomAccomodation}
                                  setNewCustomAccommodation={
                                    setNewCustomAccommodation
                                  }
                                />
                              )}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col className="text-start mx-auto m-4">
            <div className="row g-3 align-items-end">


              {/*
                <button onClick={exportToHTML} className="btn btn-primary ms-1">
                  Export as HTML
                </button>
                */}

                <div class="text-btn-main2">{"Save your Itinerary"}</div>
                <div class="text-btn-sub2">{"IF you save, you can easily come back later to add days or modify the ones you have already made."}</div>

                <div class="col-sm-12">
                {!hideControls && (
                  <>
                  <div class="">
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      onBlur={saveEmailAddress}
                      className="form-control mb-1"
                    />
                  </div>
                  <div class="">
                    <button
                      onClick={saveItinerary}
                      className="btn btn-success"
                    >{t("save_itinerary")}</button>
                    </div>

                  </>
                )}
                </div>
                <div class="text-btn-main2"></div>

                <div class="text-btn-main2">{"and/or"}</div>
                <div class="text-btn-sub2">{"Print your Itinerary to an Interactive PDF to take with you or print."}</div>



                <div class="col-sm-12">
                  <button
                    onClick={() => {
                      exportToPDF("detailed");
                    }}
                    className="btn btn-warning me-1 col-*"
                  >
                    <div>{t("export_detailed")}</div>
                    <div>{t("export_detailed2")}</div>
                    <div>{t("export_detailed_time")}</div>
                  </button>
                  <button
                    onClick={() => {
                      exportToPDF("simple");
                    }}
                    className="btn btn-warning me-1 col-*"
                  >
                    <div>{t("export_simple")}</div>
                    <div>{t("export_simple2")}</div>
                    <div>{t("export_simple_time")}</div>
                  </button>

                </div>


                <div></div>



            </div>
          </Col>
        </Row>
      </Container>
      <LoadFooter t={t} />
    </>
  );
}
export default Itinerary;
