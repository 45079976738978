import React from "react";
import { Container, Row,Stack, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Home() {
  return (
    <>
      <Container>
        <Row>
        <div className="header">

          </div>
        </Row>

        <Row className="justify-content-md-center">
          <Col md="8">
            <Stack gap={0}>

            <div class="row">
              <div class="col-sm-12">
              <Link to="Camino-Frances" className="btn frances text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino Francés"}</div>
                <div class="text-btn-sub">{"From St. Jean Pied de Port to Santiago de Compostela"}</div>
              </Link>
              </div>


              {/*



              <div class="col-sm-3">
              <Link to="Camino-Frances-Last-100" className="btn frances text-uppercase brand-btn">
                <div class="text-btn-main">{"Last 100km"}</div>
                <div class="text-btn-sub">{"From Sarria"}</div>
              </Link>
              </div>
              */}

            </div>

              {/*
                <div className="btn3" >
                  <Link to="Camino-Frances-Body" className="btn btn-3col frances text-uppercase brand-btn">
                    <div class="text-btn-main">{"BODY"}</div>
                    <div class="text-btn-sub">{"SJPP to Burgos"}</div>
                  </Link>
                  <Link to="Camino-Frances-Mind" className="btn btn-3col frances text-uppercase brand-btn">
                    <div class="text-btn-main">{"MIND"}</div>
                    <div class="text-btn-sub">{"Burgos to Astorga"}</div>
                  </Link>
                  <Link to="Camino-Frances-Soul" className="btn btn-3col frances text-uppercase brand-btn">
                    <div class="text-btn-main">{"SOUL"}</div>
                    <div class="text-btn-sub">{"Astorga to Santiago"}</div>
                  </Link>
                </div>

                */}

                {/*
                  */}

              <Link to="Camino-Del-Norte" className="btn norte text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino del Norte"}</div>
                <div class="text-btn-sub">{"From Irun to Santiago de Compostela"}</div>
              </Link>


              <div className="btn3" >
                <Link to="Camino-Portugues-Central" className="btn btn-3col portugues text-uppercase brand-btn">
                  <div class="text-btn-main">{"Portugués Central"}</div>
                  <div class="text-btn-sub">{"Lisbon to Santiago"}</div>
                </Link>
                <Link to="Camino-Portugues-Coastal" className="btn btn-3col portugues text-uppercase brand-btn">
                  <div class="text-btn-main">{"Portugués Coastal"}</div>
                  <div class="text-btn-sub">{"Porto to Santiago"}</div>
                </Link>
                <Link to="Camino-Portugues-Variante" className="btn btn-3col portugues text-uppercase brand-btn">
                  <div class="text-btn-main">{"Variante Espiritual"}</div>
                  <div class="text-btn-sub">{""}</div>
                </Link>
              </div>


              <Link to="Camino-Primitivo" className="btn primitivo text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino Primitivo"}</div>
                <div class="text-btn-sub">{"Villaviciosa, through Oviedo, to Santiago de Compostela"}</div>
              </Link>


              <Link to="Via-de-la-Plata" className="btn vdlp text-uppercase brand-btn">
                <div class="text-btn-main">{"Vía de la Plata & Camino Sanabrés"}</div>
                <div class="text-btn-sub">{"Sevilla to to Santiago de Compostela"}</div>
              </Link>

              <Link to="Camino-Invierno" className="btn invierno text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino de Invierno"}</div>
                <div class="text-btn-sub">{"Ponferrada to Santiago de Compostela"}</div>
              </Link>


              <Link to="Camino-Olvidado" className="btn olvidado text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino Olvidado"}</div>
                <div class="text-btn-sub">{"Bilbao to Ponferrada"}</div>
              </Link>

              {/*

              <div className="btn3" >
                <Link to="Camino-Santiago-Finisterre" className="btn btn-3col finisterre text-uppercase brand-btn">
                  <div class="text-btn-main">{"Camino to Finisterre"}</div>
                  <div class="text-btn-sub">{"Santiago to Finisterre"}</div>
                </Link>
                <Link to="Camino-Santiago-Muxia" className="btn btn-3col finisterre text-uppercase brand-btn">
                  <div class="text-btn-main">{"Camino to Múxia"}</div>
                  <div class="text-btn-sub">{"Santiago to Múxia"}</div>
                </Link>
              </div>
              */}


              <Link to="Camino-San-Salvador" className="btn salvador text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino San Salvador"}</div>
                <div class="text-btn-sub">{"León to Oviedo"}</div>
              </Link>

              <Link to="Caminho-Nascente" className="btn nascente text-uppercase brand-btn">
                <div class="text-btn-main">{"Caminho Nascente"}</div>
                <div class="text-btn-sub">{"BETA: Tavira to Troncoso"}</div>
              </Link>

              <Link to="Caminho-da-Geira" className="btn geira text-uppercase brand-btn">
                <div class="text-btn-main">{"Caminho da Geira e dos Arrieiros"}</div>
                <div class="text-btn-sub">{"BETA: Braga to Santiago"}</div>
              </Link>

              <Link to="Via-Podiensis" className="btn podiensis text-uppercase brand-btn">
                <div class="text-btn-main">{"Vía Podiensis"}</div>
                <div class="text-btn-sub">{"BETA: Le Puy-en-Velay to Saint Jean Pied de Port"}</div>
              </Link>

              <Link to="Camino-Levante" className="btn levante text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino Levante"}</div>
                <div class="text-btn-sub">{"BETA: Valencia to Zamora"}</div>
              </Link>

              <Link to="Camino-Madrid" className="btn madrid text-uppercase brand-btn">
                <div class="text-btn-main">{"Camino de Madrid"}</div>
                <div class="text-btn-sub">{"BETA: Madrid to Sahagún"}</div>
              </Link>



            </Stack>
            </Col>

        </Row>
        <div class="footer">v0.9.0 - More goodness added weekly</div>


      </Container>
    </>
  );
}
