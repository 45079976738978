

exports.download = (filename, contents) => {
    // Create an anchor tag to download the file
    const anchor = document.createElement("a");
    // Create an href that contains the contents of the file
    // encodeURIComponent encodes a text string as a valid component of a URI
    anchor.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(contents)
    );
    // set the download attribute to download the given filename
    anchor.setAttribute("download", filename);
    // Anchor tag should be invisible
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    // Fire the event
    anchor.click();
    // Remove the anchor tag
    document.body.removeChild(anchor);
  }

  exports.loadJson = (itType, lang) => {

    console.log('outside', itType+', '+lang);
      if( itType=='Camino-Frances' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'frances-planner-en.json';
      }
      if( itType=='Camino-Frances' && lang=='it') {
        console.log('inside', itType+', '+lang);
        return 'frances-planner-it.json';
      }
      if( itType=='Camino-Del-Norte' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'norte-planner-en.json';
      }
      if( itType=='Camino-Del-Norte' && lang=='de') {
        console.log('inside', itType+', '+lang);
        return 'norte-planner-de.json';
      }
      if( itType=='Camino-Portugues-Central' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'portugues-central-planner-en.json';
      }
      if( itType=='Camino-Portugues-Central' && lang=='de') {
        console.log('inside', itType+', '+lang);
        return 'portugues-central-planner-de.json';
      }
      if( itType=='Camino-Portugues-Coastal' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'portugues-coastal-planner-en.json';
      }
      if( itType=='Camino-Portugues-Coastal' && lang=='de') {
        console.log('inside', itType+', '+lang);
        return 'portugues-coastal-planner-de.json';
      }
      if( itType=='Camino-Portugues-Variante' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'portugues-variante-planner-en.json';
      }
      if( itType=='Camino-Portugues-Variante' && lang=='de') {
        console.log('inside', itType+', '+lang);
        return 'portugues-variante-planner-de.json';
      }
      if( itType=='Camino-Primitivo' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'primitivo-planner-en.json';
      }
      if( itType=='Via-de-la-Plata' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'vdlp-planner-en.json';
      }
      if( itType=='Camino-Invierno' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'invierno-planner-en.json';
      }
      if( itType=='Camino-Olvidado' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'olvidado-planner-en.json';
      }
      if( itType=='Camino-San-Salvador' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'salvador-planner-en.json';
      }
      if( itType=='Caminho-Nascente' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'nascente-planner-en.json';
      }
      if( itType=='Caminho-da-Geira' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'geira-planner-en.json';
      }
      if( itType=='Via-Podiensis' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'podiensis-planner-en.json';
      }
      if( itType=='Camino-Levante' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'levante-planner-en.json';
      }
      if( itType=='Camino-Madrid' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'madrid-planner-en.json';
      }

      if( itType=='Camino-Santiago-Finisterre' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'finisterre-planner-en.json';
      }

      if( itType=='Camino-Santiago-Muxia' && (lang=='' || lang=='en')) {
        console.log('inside', itType+', '+lang);
        return 'muxia-planner-en.json';
      }





  }

  exports.validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
