import React from 'react'

const FieldTypeSwitch = ({fieldType}) => {
    switch(fieldType) {
        case "Private":
          return <img src="/assets/img/icons/type_Private.png" width="18" alt="Private" title="Private Albergue" />
          break;

        case "Pension":
          return <img src="/assets/img/icons/type_Pension.png" width="18" alt="Pension" title="Pension" />
          break;

        case "Parochial":
          return <img src="/assets/img/icons/type_Parochial.png" width="18" alt="Parochial" title="Parochial Albergue" />
          break;

        case "Hostal":
          return <img src="/assets/img/icons/type_Hostal.png" width="18" alt="Hostal" title="Hostal" />
          break;

        case "Hotel":
          return <img src="/assets/img/icons/type_Hotel.png" width="18" alt="Hotel" title="Hotel" />
          break;

        case "Association":
          return <img src="/assets/img/icons/type_Association.png" width="18" alt="Association" title="Association Albergue" />
          break;

        case "Municipal":
          return <img src="/assets/img/icons/type_Municipal.png" width="18" alt="Municipal" title="Municipal Albergue"  />
          break;

        case "Casa Rural":
          return <img src="/assets/img/icons/type_CasaRural.png" width="18" alt="Casa Rural" title="Casa Rural" />
          break;

        case "Hotel Rural":
          return <img src="/assets/img/icons/type_HotelRural.png" width="18" alt="Hotel Rural" title="Hotel Rural" />
          break;

        case "Camping":
          return <img src="/assets/img/icons/type_Camping.png" width="18" alt="Camping" title="Campsite" />
          break;

        case "Xunta":
        return <img src="/assets/img/icons/type_Xunta.png" width="18" alt="Xunta" title="Xunta Albergue" />
        break;

        case "Parador":
        return <img src="/assets/img/icons/type_Parador.png" width="18" alt="Parador" title="Parador" />
        break;

        case "Bombeiro":
        return <img src="/assets/img/icons/type_Bombeiro.png" width="18" alt="Bombeiro" title="Parador" />
        break;

        case "Apartment":
        return <img src="/assets/img/icons/type_Apartment.png" width="18" alt="Apartment" title="Parador" />
        break;

        case "Polideportivo":
        return <img src="/assets/img/icons/type_Polideportivo.png" width="18" alt="Apartment" title="Polideportivo" />
        break;



        default:
          return  fieldType
      }

}
export default function FieldType({fieldType}) {
  return (
    <><FieldTypeSwitch fieldType={fieldType} /></>
  )
}
