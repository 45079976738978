export const TRANSLATIONS_EN = {
  export_simple: "Export Simple PDF",
  export_simple2: "Only Selected Places",
  export_simple_time: "10-15 sec",
  export_detailed: "Export Detailed PDF",
  export_detailed2: "Selected Places and Every Place Between",
  export_detailed_time: "20-50 sec",
  save_itinerary: "Save Itinerary",

  frances_h1: "The Camino Francés",
  frances_h2: "powered by wisepilgrim.com",

  norte_h1: "The Camino del Norte",
  norte_h2: "powered by wisepilgrim.com",

  portugues_central_h1: "The Camino Portugues Central Route",
  portugues_central_h2: "powered by wisepilgrim.com",

  portugues_coastal_h1: "The Camino Portugues Coastal Route",
  portugues_coastal_h2: "powered by wisepilgrim.com",

  portugues_variante_h1: "The Camino Portugues Variante Espiritual",
  portugues_variante_h2: "powered by wisepilgrim.com",

  primitivo_h1: "The Camino Primitivo",
  primitivo_h2: "powered by wisepilgrim.com",

  vdlp_h1: "The Vía de la Plata",
  vdlp_h2: "powered by wisepilgrim.com",

  geira_h1: "Caminho da Geira e dos Arrieiros",
  geira_h2: "powered by wisepilgrim.com",

  levante_h1: "The Camino Levante",
  levante_h2: "powered by wisepilgrim.com",

  madrid_h1: "The Camino de Madrid",
  madrid_h2: "powered by wisepilgrim.com",

  nascente_h1: "The Caminho Nascente",
  nascente_h2: "powered by wisepilgrim.com",

  olvidado_h1: "The Camino Olvidado",
  olvidado_h2: "powered by wisepilgrim.com",

  podiensis_h1: "The Vía Podiensis",
  podiensis_h2: "powered by wisepilgrim.com",

  salvador_h1: "The Camino San Salvador",
  salvador_h2: "powered by wisepilgrim.com",

  invierno_h1: "The Camino de Invierno",
  invierno_h2: "powered by wisepilgrim.com",

  finisterre_h1: "The Camino to Finisterre",
  finisterre_h2: "powered by wisepilgrim.com",

  muxia_h1: "The Camino to Muxía",
  muxia_h2: "powered by wisepilgrim.com",

  footer_h2:  "Thank you for using the Wise Pilgrim camino planner.  Please consider supporting if you can.",

  modal_heading: "Your itinerary has been saved",
  modal_body:  "Your itinerary has been saved successfully. Copy the url below to share with your friends!",
  modal_copy: "Copy URL",
  modal_close: "Close",
};
