import React from "react";

export default function LangSwitcher({ lang, setLang, setFetched, i18n }) {
const switchLang = (langVar) => {
    setLang(langVar);
    setFetched(false);
    console.log('updating lang==',langVar);
    i18n.changeLanguage(langVar);
}
  switch (lang) {
    case "Camino-Frances-en":
      return (
        <>
          <span
            role="button"
            className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
          >
            <img
              src="/assets/flags/en.svg"
              alt="English"
              className="rounded-3"
            />
          </span>
          <span
            role="button"
            className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('it')}
          >
            <img
              src="/assets/flags/it.svg"
              alt="Italian"
              className="rounded-3"
            />
          </span>
        </>
      );
      break;
    case "Camino-Frances-it":
      return (
        <>
          <span
            role="button"
            className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
          >
            <img
              src="/assets/flags/en.svg"
              alt="English"
              className="rounded-3"
            />
          </span>
          <span
            role="button"
            className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('it')}
          >
            <img
              src="/assets/flags/it.svg"
              alt="Italian"
              className="rounded-3"
            />
          </span>
        </>
      );
      break;

    case "Camino-Del-Norte-de":
      return (
        <>
          <span
            role="button"
            className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
          >
            <img
              src="/assets/flags/en.svg"
              alt="English"
              className="rounded-3"
            />
          </span>
          <span
            role="button"
            className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
          >
            <img
              src="/assets/flags/de.svg"
              alt="German"
              className="rounded-3"
            />
          </span>
        </>
      );
      break;

    case "Camino-Del-Norte-en":
      return (
        <>
          <span
            role="button"
            className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
          >
            <img
              src="/assets/flags/en.svg"
              alt="English"
              className="rounded-3"
            />
          </span>
          <span
            role="button"
            className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
          >
            <img
              src="/assets/flags/de.svg"
              alt="German"
              className="rounded-3"
            />
          </span>
        </>
      );

      case "Camino-Portugues-Central-de":
        return (
          <>
            <span
              role="button"
              className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
            >
              <img
                src="/assets/flags/en.svg"
                alt="English"
                className="rounded-3"
              />
            </span>
            <span
              role="button"
              className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
            >
              <img
                src="/assets/flags/de.svg"
                alt="German"
                className="rounded-3"
              />
            </span>
          </>
        );
        break;

      case "Camino-Portugues-Central-en":
        return (
          <>
            <span
              role="button"
              className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
            >
              <img
                src="/assets/flags/en.svg"
                alt="English"
                className="rounded-3"
              />
            </span>
            <span
              role="button"
              className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
            >
              <img
                src="/assets/flags/de.svg"
                alt="German"
                className="rounded-3"
              />
            </span>
          </>
        );

        case "Camino-Portugues-Coastal-de":
          return (
            <>
              <span
                role="button"
                className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
              >
                <img
                  src="/assets/flags/en.svg"
                  alt="English"
                  className="rounded-3"
                />
              </span>
              <span
                role="button"
                className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
              >
                <img
                  src="/assets/flags/de.svg"
                  alt="German"
                  className="rounded-3"
                />
              </span>
            </>
          );
          break;

        case "Camino-Portugues-Coastal-en":
          return (
            <>
              <span
                role="button"
                className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
              >
                <img
                  src="/assets/flags/en.svg"
                  alt="English"
                  className="rounded-3"
                />
              </span>
              <span
                role="button"
                className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
              >
                <img
                  src="/assets/flags/de.svg"
                  alt="German"
                  className="rounded-3"
                />
              </span>
            </>
          );
          case "Camino-Portugues-Variante-de":
            return (
              <>
                <span
                  role="button"
                  className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
                >
                  <img
                    src="/assets/flags/en.svg"
                    alt="English"
                    className="rounded-3"
                  />
                </span>
                <span
                  role="button"
                  className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
                >
                  <img
                    src="/assets/flags/de.svg"
                    alt="German"
                    className="rounded-3"
                  />
                </span>
              </>
            );
            break;

          case "Camino-Portugues-Variante-en":
            return (
              <>
                <span
                  role="button"
                  className="flag border border-4 border-danger rounded-3 ms-1 p-1" onClick={() => switchLang('en')}
                >
                  <img
                    src="/assets/flags/en.svg"
                    alt="English"
                    className="rounded-3"
                  />
                </span>
                <span
                  role="button"
                  className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
                >
                  <img
                    src="/assets/flags/de.svg"
                    alt="German"
                    className="rounded-3"
                  />
                </span>
              </>
            );


      break;
      case "Camino-Invierno-en":
        return (
          <>
            <span
            >
            {/*

              <img
                src="/assets/flags/en.svg"
                alt="English"
                className="rounded-3"
              />
            </span>
            <span
              role="button"
              className="flag border border-4 border-white rounded-3 ms-1 p-1" onClick={() => switchLang('de')}
            >
              <img
                src="/assets/flags/de.svg"
                alt="German"
                className="rounded-3"
              />
              */}

            </span>
          </>
        );


  break;

  }
}
