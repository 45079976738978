import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

export default function FooterNortePlannerDe({t}) {
  return (
    <Container>
    <Row>
      <Col className='text-center'><h3 className='text-center'>{t("footer_h2")}</h3></Col>
    </Row>
    <Row className="justify-content-center gx-10">
      <div className='text-center col 4 col-sm-2 col-md-2 applink'>
        <h3>The App</h3>
        <a href="http://onelink.to/ahveqa" target="_blank" className='applink-qr'>
        <img className='img-fluid' src="/assets/img/footers/onlink_to_ahveqa_small.png" /></a>
      </div>
      <div className='text-center col 4 col-sm-2 col-md-2 shopcode'>
        <h3>The Book</h3>
        <a href="https://qr.shopify.com/3BpG_8dn" target="_blank">
        <img className='img-fluid' src="/assets/img/footers/norte-shopcode.png" /></a>
      </div>
      <div className='text-center col 4 col-sm-2 col-md-2 shopcode'>
        <h3>Donation</h3>
        <a href="https://qr.shopify.com/drJnHx0G" target="_blank">
        <img className='img-fluid' src="/assets/img/footers/norte-donation-shopcode.png" /></a>
      </div>
      <div className='text-center col 4 col-sm-2 col-md-2 shopcode'>
        <h3>Wall Maps</h3>
        <a href="https://qr.shopify.com/YLwVr3n3" target="_blank">
        <img className='img-fluid' src="/assets/img/footers/bigmap-shopcode.png" /></a>
      </div>
    </Row>
    </Container>
  )
}
