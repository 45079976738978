import React, { useRef } from "react";

export default function CustomAccommodationsReadOnly({
  accommodationObj,
  deleteCustomAccomodation,
  item,
  newCustomAccommodation,
  addCustomAccomodation,
  setNewCustomAccommodation,
}) {
  const cAccom = useRef(null);
  const handleAddAccommodation = (e) => {
    console.log("cAccom=", cAccom.current.value);

    setNewCustomAccommodation(cAccom.current.value);
    cAccom.current.value = "";
  };
  return (
    <>
      {accommodationObj.length
        ? accommodationObj.map((accItem, accIndex) => {
            if (typeof accItem.name !== "undefined") {
              return (
                <tr key={accIndex}>
                  <td colSpan="13">
                    <div className="input-group mb-3 mt-3 w-50">
                      <strong>{accItem.name}</strong>
                      
                    </div>
                  </td>
                </tr>
              );
            }
          })
        : ""}
    </>
  );
}
