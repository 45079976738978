import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ReactGA from 'react-ga';
// import ReactGA from "react-ga4";
import GA4React from "ga-4-react";


import Home from "./pages/Home";
import Itinerary from "./pages/Itinerary";

function App() {
  try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-HQQ14RQVET");
      ga4react.initialize().catch(err => console.error(err));
    }, 4000);
  } catch (err) {
        console.error(err);
  }

  // ReactGA.initialize('G-HQQ14RQVET');
  // //ReactGA.pageview(window.location.pathname + window.location.search);
  // ReactGA.send("pageview");

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/Camino-Frances" element={<Itinerary itineraryType="Camino-Frances" />}></Route>
      <Route path="/Camino-Del-Norte" element={<Itinerary itineraryType="Camino-Del-Norte" />}></Route>
      <Route path="/Camino-Primitivo" element={<Itinerary itineraryType="Camino-Primitivo" />}></Route>
      <Route path="/Camino-Portugues-Central" element={<Itinerary itineraryType="Camino-Portugues-Central" />}></Route>
      <Route path="/Camino-Portugues-Coastal" element={<Itinerary itineraryType="Camino-Portugues-Coastal" />}></Route>
      <Route path="/Camino-Portugues-Variante" element={<Itinerary itineraryType="Camino-Portugues-Variante" />}></Route>
      <Route path="/Via-de-la-Plata" element={<Itinerary itineraryType="Via-de-la-Plata" />}></Route>
      <Route path="/Camino-Invierno" element={<Itinerary itineraryType="Camino-Invierno" />}></Route>
      <Route path="/Camino-Olvidado" element={<Itinerary itineraryType="Camino-Olvidado" />}></Route>
      <Route path="/Camino-Santiago-Finisterre" element={<Itinerary itineraryType="Camino-Santiago-Finisterre" />}></Route>
      <Route path="/Camino-Santiago-Muxia" element={<Itinerary itineraryType="Camino-Santiago-Muxia" />}></Route>
      <Route path="/Camino-San-Salvador" element={<Itinerary itineraryType="Camino-San-Salvador" />}></Route>
      <Route path="/Caminho-Nascente" element={<Itinerary itineraryType="Caminho-Nascente" />}></Route>
      <Route path="/Caminho-da-Geira" element={<Itinerary itineraryType="Caminho-da-Geira" />}></Route>
      <Route path="/Via-Podiensis" element={<Itinerary itineraryType="Via-Podiensis" />}></Route>
      <Route path="/Camino-Levante" element={<Itinerary itineraryType="Camino-Levante" />}></Route>
      <Route path="/Camino-Madrid" element={<Itinerary itineraryType="Camino-Madrid" />}></Route>
      <Route path="/" element={<Home />}></Route>

      <Route path="/Camino-Frances/:uuid" element={<Itinerary  itineraryType="Camino-Frances" hideControls={false} />}></Route>
      <Route path="/Camino-Del-Norte/:uuid" element={<Itinerary  itineraryType="Camino-Del-Norte" hideControls={false} />}></Route>
      <Route path="/Camino-Primitivo/:uuid" element={<Itinerary  itineraryType="Camino-Primitivo" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Central/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Central" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Coastal/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Coastal" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Variante/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Variante" hideControls={false} />}></Route>
      <Route path="/Via-de-la-Plata/:uuid" element={<Itinerary  itineraryType="Via-de-la-Plata" hideControls={false} />}></Route>
      <Route path="/Camino-Invierno/:uuid" element={<Itinerary  itineraryType="Camino-Invierno" hideControls={false} />}></Route>
      <Route path="/Camino-Olvidado/:uuid" element={<Itinerary  itineraryType="Camino-Olvidado" hideControls={false} />}></Route>
      <Route path="/Camino-Santiago-Finisterre/:uuid" element={<Itinerary  itineraryType="Camino-Santiago-Finisterre" hideControls={false} />}></Route>
      <Route path="/Camino-Santiago-Muxia/:uuid" element={<Itinerary  itineraryType="Camino-Santiago-Muxia" hideControls={false} />}></Route>
      <Route path="/Camino-San-Salvador/:uuid" element={<Itinerary  itineraryType="Camino-San-Salvador" hideControls={false} />}></Route>
      <Route path="/Caminho-Nascente/:uuid" element={<Itinerary  itineraryType="Caminho-Nascente" hideControls={false} />}></Route>
      <Route path="/Caminho-da-Geira/:uuid" element={<Itinerary  itineraryType="Caminho-da-Geira" hideControls={false} />}></Route>
      <Route path="/Via-Podiensis/:uuid" element={<Itinerary  itineraryType="Via-Podiensis" hideControls={false} />}></Route>
      <Route path="/Camino-Levante/:uuid" element={<Itinerary  itineraryType="Camino-Levante" hideControls={false} />}></Route>
      <Route path="/Camino-Madrid/:uuid" element={<Itinerary  itineraryType="Camino-Madrid" hideControls={false} />}></Route>

      <Route path="/Camino-Frances/edit/:uuid" element={<Itinerary  itineraryType="Camino-Frances" hideControls={false} />}></Route>
      <Route path="/Camino-Del-Norte/edit/:uuid" element={<Itinerary  itineraryType="Camino-Del-Norte" hideControls={false} />}></Route>
      <Route path="/Camino-Primitivo/edit/:uuid" element={<Itinerary  itineraryType="Camino-Primitivo" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Central/edit/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Central" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Coastal/edit/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Coastal" hideControls={false} />}></Route>
      <Route path="/Camino-Portugues-Variante/edit/:uuid" element={<Itinerary  itineraryType="Camino-Portugues-Variante" hideControls={false} />}></Route>
      <Route path="/Via-de-la-Plata/edit/:uuid" element={<Itinerary  itineraryType="Via-de-la-Plata" hideControls={false} />}></Route>
      <Route path="/Camino-Invierno/edit/:uuid" element={<Itinerary  itineraryType="Camino-Invierno" hideControls={false} />}></Route>
      <Route path="/Camino-Olvidado/edit/:uuid" element={<Itinerary  itineraryType="Camino-Olvidado" hideControls={false} />}></Route>
      <Route path="/Camino-Santiago-Finisterre/edit/:uuid" element={<Itinerary  itineraryType="Camino-Santiago-Finisterre" hideControls={false} />}></Route>
      <Route path="/Camino-Santiago-Muxia/edit/:uuid" element={<Itinerary  itineraryType="Camino-Santiago-Muxia" hideControls={false} />}></Route>
      <Route path="/Camino-San-Salvador/edit/:uuid" element={<Itinerary  itineraryType="Camino-San-Salvador" hideControls={false} />}></Route>
      <Route path="/Caminho-Nascente/edit/:uuid" element={<Itinerary  itineraryType="Caminho-Nascente" hideControls={false} />}></Route>
      <Route path="/Caminho-da-Geira/edit/:uuid" element={<Itinerary  itineraryType="Caminho-da-Geira" hideControls={false} />}></Route>
      <Route path="/Via-Podiensis/edit/:uuid" element={<Itinerary  itineraryType="Via-Podiensis" hideControls={false} />}></Route>
      <Route path="/Camino-Levante/edit/:uuid" element={<Itinerary  itineraryType="Camino-Levante" hideControls={false} />}></Route>
      <Route path="/Camino-Madrid/edit/:uuid" element={<Itinerary  itineraryType="Camino-Madrid" hideControls={false} />}></Route>



      </Routes>
    </BrowserRouter>
  );
}

export default App;
