export const TRANSLATIONS_IT = {

  export_simple: "Esporta PDF semplice",
  export_simple_time: "10-15 sec",
  export_detailed: "Export Detailed PDF",
  export_detailed_time: "20-50 sec",
  save_itinerary: "Salva itinerario",


  frances_h1: "Il Camino Francés",
  frances_h2: "un pianificatore di wisepilgrim.com",

  footer_h2:  "Grazie per aver utilizzato il pianificatore Wise Pilgrim camino. Si prega di considerare il supporto, se possibile.",

  modal_heading: "Your itinerary has been saved",
  modal_body:    "Il tuo itinerario è stato salvato con successo. Copia l'URL qui sotto per condividerlo con i tuoi amici!",
  modal_copy: "Copia URL",
  modal_close: "Chiudere",
};
