import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";


export default function HeaderMuxiaPlannerEn() {
  const { t } = useTranslation();

  return (
    <Row>
      <div className="header">
        <h1><a href="/">{t("muxia_h1")}</a></h1>
        <h2 className="text-justify">{t("muxia_h2")}</h2>
        <img className="phone-rotate"
          src={
            "/assets/img/icons/phone-rotate.png"
          }
          width="180"
        />
      </div>
    </Row>

  )
}
