import React, {useRef} from 'react'

export default function CustomAccommodations({accommodationObj, deleteCustomAccomodation, item,  newCustomAccommodation, addCustomAccomodation, setNewCustomAccommodation }) {
    const cAccom = useRef(null);
    const handleAddAccommodation = (e) => {
        console.log('cAccom=',cAccom.current.value)
        
        setNewCustomAccommodation(
            cAccom.current.value
        );
        cAccom.current.value='';
        
      
    }
  return (
      <>
    {accommodationObj.length?
        accommodationObj.map((accItem, accIndex) => {
          if (typeof accItem.name !== "undefined") {
            return (
              <tr key={accIndex}>
                <td colSpan="13">
                  
                  <div className="input-group mb-3 mt-3 w-50">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Custom Accomodation"
                      aria-label="Custom Accomodation"
                      aria-describedby="basic-addon2"
                      defaultValue={accItem.name}
                      readOnly="yes"
                      
                    />
                    <span
                      className="input-group-text btn-danger"
                      id="delete-custom-accomodation"
                      role="button"
                      onClick={() => {
                        deleteCustomAccomodation(
                          item.id,
                          accItem.name
                        );
                      }}
                    >
                      -
                    </span>
                  </div>
                </td>
              </tr>
            );
          }
        }):''
      }
      <tr>
                                <td colSpan="13">
                                  <div className="input-group mb-3 mt-3 w-50">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Custom Accomodation"
                                      aria-label="Custom Accomodation"
                                      aria-describedby="basic-addon2"
                                      onBlur={handleAddAccommodation}
                                      ref={cAccom}
                                      
                                    />
                                    <span
                                      role="button"
                                      className="input-group-text btn-success"
                                      id="basic-addon2"
                                      onClick={() => {
                                        addCustomAccomodation(
                                          item.id,
                                          "test Accomodation Name"
                                        );
                                      }}
                                    >
                                      +
                                    </span>
                                  </div>
                                </td>
                              </tr>
 </> )
}
