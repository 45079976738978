import React,{useState, useEffect} from 'react'
import { Modal, Button } from "react-bootstrap";

export default function ModalWindow({t, setShowModal, shareUrl}) {

  const copyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    alert('Url copied to clipboard!');
  }


  const [show, setShow] = useState(false);
  const [loadModal, setLoadModal] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowModal(false);
  }
  const handleShow = () => setShow(true);
  
  useEffect(()=>{
    if(!loadModal) {
      setShow(true)
      setLoadModal(true);
    }
  },[loadModal])
  return (
    <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("modal_heading")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("modal_body")}
        <p>
          <input type="text" className='form-control' defaultValue={shareUrl} />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        {t("modal_close")}
        </Button>
        <Button variant="primary" onClick={copyUrl}>
        {t("modal_copy")}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}
