export const TRANSLATIONS_DE = {
  export_simple: "Vereinfachte PDF exportieren",
  export_simple_time: "10-15 sekunden",
  export_detailed: "Detaillierte PDF exportieren",
  export_detailed_time: "20-50 sekunden",
  save_itinerary: "Reiseplan speichern",

  frances_h1: "Der Camino Francés",
  frances_h2: "ein wisepilgrim.com planer",

  norte_h1: "Der Camino del Norte - Küstenweg",
  norte_h2: "ein wisepilgrim.com planer",

  portugues_central_h1: "Der Portugiesische Jakobsweg - ZENTRALWEG",
  portugues_central_h2: "ein wisepilgrim.com planer",

  portugues_coastal_h1: "Der Portugiesische Jakobsweg - KÜSTENWEG",
  portugues_coastal_h2: "ein wisepilgrim.com planer",

  portugues_variante_h1: "Der Portugiesische Jakobsweg - VARIANTE ESPIRITUAL",
  portugues_variante_h2: "ein wisepilgrim.com planer",

  footer_h2: "Vielen Dank, dass Sie den Camino-Planer von Wise Pilgrim verwenden. Bitte erwägen Sie eine Unterstützung, wenn Sie können.",

  modal_heading: "Your itinerary has been saved",
  modal_body: "Ihre Reiseroute wurde erfolgreich gespeichert. Kopieren Sie die URL unten, um sie mit Ihren Freunden zu teilen!",
  modal_copy: "URL kopieren",
  modal_close: "Schließen",
};
